import React, { useState, useEffect, useContext, useRef } from "react";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import IconButton from "@mui/material/IconButton/IconButton";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { clientService } from "../../utils/services/clientService";
import AppContext from "../context/context";
import { productService } from "../../utils/services/productService";
import { statusTypes } from "../../utils/services/statusTypes";
import { imageService } from "../../utils/services/imageService";
import config from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import CustomTypography from "../../globalStyles/fonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import ProductDetailsModal from "../productViewDetailsModal";
import CloseIcon from "@mui/icons-material/Close";
import { currencyTypes } from "../../utils/services/currencyTypes";
import { isMobile } from "../../globalStyles/mobile";
import ClientAutocomplete from "../clientAutocomplete";
import { supplierService } from "../../utils/services/supplierService";
import InputWithUnit from "../Input-with-unit";
const defaultImage = "/product.png";

const ProductList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteProduct,
  onSelectClient,
  viewMode,
  filter,
  setFilter,
  deleteTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [product_type, setProductType] = useState("");
  const [variants, setVariants] = useState([]);
  const fileInputRef = useRef(null);
  const [imageData, setImageData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [clientData, setClientData] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [coverImageIndex, setCoverImageIndex] = useState(-1);
  const [featureImages, setFeatureImages] = useState([]);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const [currency, setCurrency] = useState("");
  const [vendor, setVendor] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { user } = useContext(AppContext);
  const modalRef = useRef(null);

  const weightUnitOptions = ["mg", "g", "kg", "t", "mt", "gt", "lb", "oz"];
  const sizeOptions = ["Small", "Medium", "Large"];
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  const fetchSupplierData = async () => {
    try {
      const response = await supplierService.fetch();
      setVendors(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    fetchClientData();
    fetchSupplierData();
  }, []);

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setName(product.name);

    const plainTextDescription = stripHtmlTags(product.description);

    setDescription(plainTextDescription);
    setStatus(product.status);
    setProductType(product.product_type);
    setVariants(product.variants);
    setCurrency(product.currency);
    setVendor(product.vendor);
    setSelectedClient({
      name: product?.client_id?.business_name || product?.client_id?.name || "",
      _id: product.client_id?._id,
    });
    setOpenModal(true);

    const fetchedImages = product.images.map(fetchImage);
    setSelectedImages(
      product.images.map((img) => ({
        image: img.image,
        feature_image: img.feature_image,
      }))
    );
    setImageUrls(fetchedImages);
    const coverIndex = product.images.findIndex((img) => img.feature_image);
    setCoverImageIndex(coverIndex);
    setFeatureImages(product.images.map((img) => img.feature_image));
  };

  const handleDeleteClick = (product) => {
    setSelectedProduct(product);
    setOpenConfirmation(true); // Open confirmation dialog before deleting carrier
  };
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const newUploadedImages = [];
    const newImageUrls = [];

    for (let file of files) {
      const imageData = await imageService.upload(file);
      newUploadedImages.push(imageData.key);
      newImageUrls.push(URL.createObjectURL(file));
    }

    let updatedImages = [...selectedImages];
    let updatedUrls = [...imageUrls];

    if (editingImageIndex !== null) {
      updatedImages[editingImageIndex] = {
        ...updatedImages[editingImageIndex],
        image: newUploadedImages[0],
      };
      updatedUrls[editingImageIndex] = newImageUrls[0];
      setEditingImageIndex(null);
    }

    for (
      let i = editingImageIndex !== null ? 1 : 0;
      i < newUploadedImages.length;
      i++
    ) {
      updatedImages.push({
        image: newUploadedImages[i],
        feature_image: false,
      });
      updatedUrls.push(newImageUrls[i]);
    }

    const updatedSelectedProduct = {
      ...selectedProduct,
      images: updatedImages,
    };

    setSelectedProduct(updatedSelectedProduct);
    setSelectedImages(updatedImages);
    setImageUrls(updatedUrls);
  };

  const clientId = localStorage.getItem("client_id");

  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await productService.delete(selectedProduct._id);
      onDeleteData && onDeleteData();
      toast.success("Product Deleted successfully!");
      setAnchorEl(null);
      setOpenConfirmation(false);
      setLoading(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Product name is required";
    }
    if (!currency) {
      errors.currency = "Currency is required";
    }
    if (!status) {
      errors.status = "Status is required";
    }
    if (!selectedClient) {
      errors.selectedClient = "Client is required";
    }
    variants.forEach((variant, index) => {
      if (!variant.price) {
        errors[`price-${index}`] = "Price is required";
      }
      if (!variant.weight_unit) {
        errors[`weight_unit-${index}`] = "Weight unit is required";
      }
      if (!variant.weight) {
        errors[`weight-${index}`] = "Weight  is required";
      }
      if (!variant.height_unit) {
        errors[`height_unit-${index}`] = "Height unit is required";
      }
      if (!variant.height) {
        errors[`height-${index}`] = "Height  is required";
      }
      if (!variant.width_unit) {
        errors[`width_unit-${index}`] = "Width unit is required";
      }
      if (!variant.width) {
        errors[`width-${index}`] = "Width  is required";
      }
      if (!variant.length) {
        errors[`length-${index}`] = "Length unit is required";
      }
      if (!variant.length) {
        errors[`length-${index}`] = "Length  is required";
      }
      if (!variant.sku) {
        errors[`sku-${index}`] = "SKU unit is required";
      }
      if (variant.price < 0) {
        errors[`price-${index}`] = "Price must be greater than 0";
      }
      if (variant.weight < 0) {
        errors[`weight-${index}`] = "Weight must be greater than 0";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditProduct = async (e) => {
    e.preventDefault();
    if (!validateForm()) return toast.warning("Please fill all the fields");

    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;

      const imageField =
        selectedImages.length > 0
          ? selectedImages.map((image) => ({
              image: image.image,
              feature_image: image.feature_image,
            }))
          : selectedProduct.images;

      const response = await productService.edit(
        {
          name: name,
          description: description,
          images: imageField,
          status: status,
          product_type: product_type,
          variants: variants,
          currency: currency,
          vendor: vendor?._id,
          client_id: client_id,
        },
        selectedProduct._id
      );

      onEditData && onEditData();
      toast.success("Product Edited successfully!");
      setFormErrors({});
    } catch (error) {
      console.error("Edit failed:", error);
    } finally {
      setOpenModal(false);
      setAnchorEl(null);
      setLoading(false);
    }
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    if (updatedVariants[index]) {
      updatedVariants[index][field] = value;
      setVariants(updatedVariants);
    }
  };
  const addVariant = () => {
    setVariants([
      ...variants,
      {
        option: "",
        price: "",
        weight: "",
        weight_unit: "",
        height: "",
        height_unit: "",
        width: "",
        width_unit: "",
        length: "",
        length_unit: "",
        sku: "",
        inventory: "",
      },
    ]);
  };
  const removeVariant = (indexToRemove) => {
    const filteredVariants = variants.filter(
      (_, index) => index !== indexToRemove
    );
    setVariants(filteredVariants);
  };

  const handleInputChange = (event, value) => {
    setStatus(value);
  };
  const handleCurrencyChange = (event, value) => {
    setCurrency(value);
  };
  const handleVendorChange = (event, value) => {
    setVendor(value);
  };
  const removeSelectedImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setImageUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    const updatedImages = [...selectedProduct.images];
    updatedImages.splice(indexToRemove, 1);
    const updatedSelectedProduct = {
      ...selectedProduct,
      images: updatedImages,
    };
    setSelectedProduct(updatedSelectedProduct);
    setImageData(updatedImages);
    if (coverImageIndex === indexToRemove) {
      setCoverImageIndex(-1);
      setFeatureImages((prevFeatureImages) => {
        const updatedFeatureImages = [...prevFeatureImages];
        updatedFeatureImages[indexToRemove] = false;
        return updatedFeatureImages;
      });
    }
  };

  const handleChooseImage = (index) => {
    setEditingImageIndex(index);
    fileInputRef.current.click();
  };
  const handleCheckCoverImage = (index) => {
    const updatedFeatureImages = featureImages.map((image, idx) =>
      idx === index ? !image : false
    );
    setFeatureImages(updatedFeatureImages);
    const selectedIndex = selectedImages.findIndex((_, idx) => idx === index);
    const updatedImages = selectedImages.map((image, idx) => ({
      ...image,
      feature_image: idx === selectedIndex,
    }));

    setSelectedImages(updatedImages);
    setImageData(updatedImages);
  };

  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`;
    }
    return defaultImage;
  };
  const fetchProductImage = (images) => {
    if (images.length) {
      const featureImage = images?.find((img) => img.feature_image);
      if (featureImage && featureImage.image) {
        return `${config.baseURL}/fetch-file/${featureImage.image}`;
      } else {
        return `${config.baseURL}/fetch-file/${images[0].image}`;
      }
    }
    return defaultImage;
  };
  function stripHtmlTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const plainText = tempDiv.textContent || tempDiv.innerText || "";
    return plainText.replace(/\s\s+/g, " ").trim();
  }
  const handleChangeDescription = (description) => {
    setDescription(description);
  };

  const handleFilterChange = (newFilter) => {
    if (newFilter === "All") {
      setFilter(newFilter);
    } else {
      setFilter(newFilter.toLowerCase());
      setSelectedItems([]);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedItems(data.map((product) => product._id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelected);
  };
  const handleDelete = () => {
    onDeleteData(selectedItems);
    setSelectedItems([]);
  };

  const filteredData = data.filter((product) =>
    filter === "All" ? true : product.status === filter
  );
  const handleViewClick = (product) => {
    setSelectedProduct(product);
    setOpenViewModal(true);
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: 1,
        maxHeight: "90vh",
        paddingBottom: 10,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {data?.map((product, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={fetchImage(
                      product.images.find((img) => img.feature_image) || {}
                    )}
                    alt={product.name}
                    style={{
                      width: !product.image ? 65 : 60,
                      height: !product.image ? 65 : 60,
                      objectFit: "cover",
                      marginRight: 10,
                      borderRadius: "60%",
                      border: !product.image ? "" : "2px solid #18E7CA",
                      padding: !product.image ? 0 : 1,
                    }}
                  />
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 15,
                  }}
                >
                  <Tooltip title={product?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                        }}
                        variant="h7"
                      >
                        {product?.name}
                      </Typography>
                    </div>
                  </Tooltip>

                  <Tooltip title="Variants" arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                        }}
                        variant="h7"
                      >
                        {/* Price: ${product?.variants[0]?.price} */}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={stripHtmlTags(product?.description)} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "80%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                        }}
                        variant="h7"
                      >
                        Description: {stripHtmlTags(product?.description)}
                      </Typography>
                    </div>
                  </Tooltip>

                  <Tooltip title={product?.client_id?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {user.role === "admin" ? (
                        <Typography
                          style={{
                            fontWeight: "500",
                          }}
                          variant="h7"
                        >
                          Client : {product?.client_id?.name}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <IconButton
                    style={{
                      color: "#FEC50F",
                      padding: 0,
                      background: "#FEC50F33",
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 15,
                      boxShadow: "0px 0px 4px 0px #00000040",
                      marginTop: 5,
                    }}
                    aria-label="edit"
                    onClick={() => {
                      if (client) {
                        handleEditClient && handleEditClient(product);
                      } else {
                        handleEditClick(product);
                      }
                    }}
                  >
                    <BorderColorIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>

                  <IconButton
                    style={{
                      color: "#D13438",
                      padding: 0,
                      background: "#D1343833",
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: "0px 0px 4px 0px #00000040",
                      marginTop: 5,
                    }}
                    aria-label="delete"
                    onClick={() => {
                      handleDeleteClick(product);
                    }}
                  >
                    <img
                      style={{ height: 15, width: 15 }}
                      alt="delete"
                      src="/delete.png"
                    ></img>
                  </IconButton>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((product, index) => (
            <Card key={index} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Checkbox
                    checked={selectedItems.indexOf(product._id) !== -1}
                    onChange={(event) =>
                      handleCheckboxClick(event, product._id)
                    }
                  />
                </Box>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={fetchProductImage(product.images)}
                    alt={product.name}
                    style={{
                      width: 80,
                      height: 90,
                      objectFit: "contain",
                      marginBottom: 20,
                    }}
                  />
                  <div style={{ width: "70%" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                        sx={{
                          maxWidth: "90%",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          variant="h6"
                        >
                          {product?.name}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Price : ${product.variants[0]?.price}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Weight : {product.variants[0]?.weight}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>SKU: {product.variants[0]?.sku}</Typography>
                      </Box>
                      {/* <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Category: {product.category || ""}
                        </Typography>
                      </Box> */}
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Status :{" "}
                          <Chip
                            label={product.status}
                            sx={{
                              padding: "2px 4px",
                              borderRadius: "4px",
                              backgroundColor:
                                product.status.toLowerCase() === "active"
                                  ? "#CBFFD3"
                                  : "#E1E1E1",
                            }}
                          />
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                        sx={{
                          maxWidth: "80%",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Vendor : {product.vendor?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <IconButton
                        style={{
                          color: "#007DFF",
                          padding: 0,
                          background: "#C3E1FF",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(product);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="delete"
                          src="/eye.png"
                        ></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#FEC50F",
                          padding: 0,
                          background: "#FEC50F33",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 15,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          marginTop: 5,
                        }}
                        aria-label="edit"
                        onClick={() => {
                          if (client) {
                            handleEditClient && handleEditClient(product);
                          } else {
                            handleEditClick(product);
                          }
                        }}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>

                      <IconButton
                        style={{
                          color: "#D13438",
                          padding: 0,
                          background: "#D1343833",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          marginTop: 5,
                        }}
                        aria-label="delete"
                        onClick={() => {
                          handleDeleteClick(product);
                        }}
                      >
                        <img
                          style={{ height: 15, width: 15 }}
                          alt="delete"
                          src="/delete.png"
                        ></img>
                      </IconButton>
                    </Box>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };
  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile() ? "column" : "row",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box sx={{ display: "flex", gap: 2, mb: 2, mt: 2 }}>
            <Button
              onClick={() => handleFilterChange("All")}
              style={{
                backgroundColor: filter === "All" ? "#151D48" : "#FFFFFF",
                color: filter === "All" ? "#ffff" : "#000",
                padding: 10,
                width: 100,
                textTransform: "none",
              }}
            >
              <CustomTypography fontWeight={filter === "All" ? 600 : 400}>
                All
              </CustomTypography>
            </Button>
            <Button
              onClick={() => handleFilterChange("active")}
              style={{
                backgroundColor: filter === "active" ? "#151D48" : "#FFFFFF",
                color: filter === "active" ? "#ffff" : "#000",
                padding: 10,
                width: 100,
                textTransform: "none",
              }}
            >
              <CustomTypography fontWeight={filter === "active" ? 600 : 400}>
                Active
              </CustomTypography>
            </Button>
            <Button
              onClick={() => handleFilterChange("draft")}
              style={{
                backgroundColor: filter === "draft" ? "#151D48" : "#FFFFFF",
                color: filter === "draft" ? "#ffff" : "#000",
                padding: 10,
                width: 100,
                textTransform: "none",
              }}
            >
              <CustomTypography fontWeight={filter === "draft" ? 600 : 400}>
                Draft
              </CustomTypography>
            </Button>
          </Box>
        </div>
        <Box sx={{ mt: 1 }}>
          {selectedItems.length > 0 && (
            <Box sx={{ display: "flex", gap: 2, mb: 2, mr: 1 }}>
              <Button
                onClick={() => setSelectedItems([])}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderWidth: 1,
                  borderColor: "#333333",
                  width: 100,
                  color: "#333333",
                  textTransform: "none",
                }}
              >
                <CustomTypography fontSize={14} fontWeight={600}>
                  Cancel
                </CustomTypography>
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                style={{ textTransform: "none", width: 100 }}
              >
                <CustomTypography fontSize={14} fontWeight={600}>
                  Delete
                </CustomTypography>
              </Button>
            </Box>
          )}
        </Box>
      </div>

      {isMobile() ? (
        <MobileCardView data={data}></MobileCardView>
      ) : (
        <TableContainer
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 10px",
              paddingBottom: 10,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottom: "none",
                    padding: 5,
                  }}
                >
                  <Checkbox
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < filteredData.length
                    }
                    checked={
                      filteredData.length > 0 &&
                      selectedItems.length === filteredData.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Product
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    {"Price (USD)"}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Weight
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    SKU
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Category
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Status
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Vendor
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    borderBottom: "none",
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ borderBottom: "none" }}>
                    <NoDataImage />
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((product, index) => (
                  <TableRow
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      border: "none",
                      padding: 0,
                      "&:last-child td, &:last-child th": {
                        border: "none",
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        padding: 5,
                      }}
                    >
                      <Checkbox
                        checked={selectedItems.indexOf(product._id) !== -1}
                        onChange={(event) =>
                          handleCheckboxClick(event, product._id)
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: "none",
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                        // width: 200,
                        maxWidth: "150px",
                      }}
                    >
                      <img
                        src={fetchProductImage(product.images)}
                        alt={product.name}
                        style={{
                          width: 30,
                          height: 30,
                          objectFit: "cover",
                          marginRight: 10,
                          marginTop: 10,
                        }}
                      />

                      <CustomTypography
                        style={{ marginTop: 1 }}
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.name}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.variants[0]?.price}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.variants[0]?.weight}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.variants[0]?.sku}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.product_type || ""}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      {product.status === "active" ||
                      product.status === "Active" ? (
                        <CustomTypography
                          fontSize="12px"
                          // color="#007613"
                          fontWeight={400}
                        >
                          <span
                            style={{
                              // backgroundColor: "#CBFFD3",
                              padding: "5px 10px",
                              // borderColor: "#007613",
                              display: "inline-block",
                              // borderWidth: 2,
                            }}
                          >
                            {product.status}
                          </span>
                        </CustomTypography>
                      ) : (
                        <CustomTypography
                          fontSize="12px"
                          // color="#626262"
                          fontWeight={400}
                        >
                          <span
                            style={{
                              // backgroundColor: "#E1E1E1",
                              padding: "5px 10px",
                              // borderColor: "#747474",
                              display: "inline-block",
                              // borderWidth: 2,
                            }}
                          >
                            {product.status}
                          </span>
                        </CustomTypography>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none", padding: 0 }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {product.vendor?.name}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderBottom: "none",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 0,
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#007DFF",
                          padding: 0,
                          background: "#C3E1FF",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(product);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="delete"
                          src="/eye.png"
                        ></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#FEC50F",
                          padding: 0,
                          background: "#FEC50F33",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 15,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          marginTop: 5,
                        }}
                        aria-label="edit"
                        onClick={() => {
                          if (client) {
                            handleEditClient && handleEditClient(product);
                          } else {
                            handleEditClick(product);
                          }
                        }}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>

                      <IconButton
                        style={{
                          color: "#D13438",
                          padding: 0,
                          background: "#D1343833",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: "0px 0px 4px 0px #00000040",
                          marginTop: 5,
                        }}
                        aria-label="delete"
                        onClick={() => {
                          handleDeleteClick(product);
                        }}
                      >
                        <img
                          style={{ height: 15, width: 15 }}
                          alt="delete"
                          src="/delete.png"
                        ></img>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
  useEffect(() => {
    if (openModal && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [openModal]);
  return (
    <div sx={{ minWidth: "100%" }}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: "70vh",
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
        }}
      >
        {viewMode === "grid" ? renderGridView() : renderListView()}
      </div>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormErrors({});
        }}
        fullWidth
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        ref={modalRef}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{"Edit Product"}</DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />

        <DialogContent
          ref={modalRef}
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            scrollBehavior: "smooth",
          }}
          sx={{
            overflowY: "auto",
            "&::WebkitScrollbar": {
              width: "7px",
            },
            "&::WebkitScrollbar-track": {
              backgroundColor: "#D9D9D9",
              borderRadius: 14,
            },
            "&::WebkitScrollbar-thumb": {
              backgroundColor: "#273894",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">Title</Typography>
              <TextField
                // sx={{ minWidth: 300 }}
                size="small"
                autoFocus
                margin="dense"
                placeholder="Product Name"
                variant="outlined"
                type="text"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">Category</Typography>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                placeholder="Type"
                type="text"
                fullWidth
                value={product_type}
                onChange={(e) => setProductType(e.target.value)}
                error={formErrors?.productType}
                helperText={formErrors?.productType}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className="form-lable-style">Description</Typography>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={handleChangeDescription}
                placeholder="Enter product description"
                style={{
                  height: isMobile() ? 70 : "100px",
                  marginBottom: "50px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ marginTop: isMobile() ? 5 : 0 }}>
              <Typography className="form-lable-style">Media</Typography>
              <Box
                sx={{
                  width: "100%",
                  border: 1,
                  borderColor: "#D1D1D1",
                  borderRadius: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 100,
                  mt: { xs: 2, sm: 1 },
                  p: 2,
                }}
              >
                <input
                  id="productImageInput"
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />

                {selectedImages.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        gap: 2,
                        mt: 2,
                        maxHeight: 250,
                        overflowY: "auto",
                        border: "1px solid #000",
                        borderRadius: 1,
                        p: 2,
                      }}
                    >
                      {selectedImages.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            p: 1,
                            border: 1,
                            borderRadius: 1,
                            maxWidth: { xs: "100%", sm: "auto" },
                          }}
                        >
                          <img
                            src={fetchImage(image)}
                            alt={`Uploaded Image ${index}`}
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => handleChooseImage(index)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                            <input
                              type="checkbox"
                              checked={featureImages[index]}
                              onChange={() => handleCheckCoverImage(index)}
                            />
                            <IconButton
                              onClick={() => removeSelectedImage(index)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <IconButton onClick={() => handleChooseImage(null)}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    margin="dense"
                    sx={{ minWidth: 200 }}
                    color="primary"
                    onClick={() => handleChooseImage(null)}
                    style={{
                      color: "#707070",
                      marginRight: selectedImages.length > 0 ? 10 : 0,
                      textTransform: "none",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="upload"
                        style={{ marginBottom: 5, padding: 0 }}
                      >
                        <InsertPhotoIcon />
                      </IconButton>
                      <Typography variant="body2">Upload</Typography>
                    </Box>
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Currency</Typography>
              <Autocomplete
                placeholder="Select Currency"
                size="small"
                value={currency}
                onChange={handleCurrencyChange}
                options={currencyTypes}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                error={!!formErrors.currency}
              />
              {formErrors.currency && (
                <FormHelperText error>{formErrors.currency}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Status</Typography>
              <Autocomplete
                placeholder="Select Status"
                size="small"
                value={status}
                onChange={handleInputChange}
                options={statusTypes}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                error={!!formErrors.status}
              />
              {formErrors.status && (
                <FormHelperText error>{formErrors.status}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Vendor</Typography>
              <Autocomplete
                placeholder="Vendor"
                size="small"
                value={vendor?.name || vendor}
                onChange={handleVendorChange}
                options={vendors}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option?.name}</Typography>
                  </li>
                )}
                error={!!formErrors.vendor}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Vendor</Typography>
              <TextField
                autoFocus
                size="small"
                placeholder="Enter Vendor"
                type="text"
                fullWidth
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                error={formErrors?.vendor}
                helperText={formErrors?.vendor}
              />
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <Typography className="form-lable-style">Variants</Typography>

              {variants?.length ? (
                variants?.map((variant, index) => (
                  <Card
                    key={index}
                    style={{ marginBottom: 16, backgroundColor: "#EEEEEE" }}
                  >
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          style={{
                            color: "#D13438",
                            padding: 0,
                            background: "#D1343833",
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: "0px 0px 4px 0px #00000040",
                            marginTop: 5,
                          }}
                          aria-label="delete"
                          onClick={() => removeVariant(index)}
                        >
                          <img
                            style={{ height: 15, width: 15 }}
                            alt="delete"
                            src="/delete.png"
                          ></img>
                        </IconButton>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography className="form-lable-style">
                            Option
                          </Typography>
                          <TextField
                            sx={{ backgroundColor: "#FFFFFF" }}
                            type="size"
                            size="small"
                            placeholder="Option"
                            variant="outlined"
                            value={variant.option}
                            onChange={(e) =>
                              handleVariantChange(
                                index,
                                "option",
                                e.target.value
                              )
                            }
                            fullWidth
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography className="form-lable-style">
                            Price
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              style={{ backgroundColor: "#FFFFFF" }}
                              type="number"
                              size="small"
                              placeholder="Enter Price"
                              value={variant.price}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              inputProps={{ min: 0 }}
                              fullWidth
                              error={!!formErrors[`price-${index}`]}
                            />
                            {formErrors[`price-${index}`] && (
                              <FormHelperText error>
                                {formErrors[`price-${index}`]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.weight_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "weight_unit", e)
                            }
                            inputValue={variant.weight}
                            onChangeText={(e) =>
                              handleVariantChange(index, "weight", e)
                            }
                            unitType={"weight"}
                            fieldName={"Weight"}
                          ></InputWithUnit>
                          {/* <CustomTypography fontSize={14}>Weight</CustomTypography>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                marginTop: 0.5,
                                backgroundColor: '#FFFFFF'
                              }}
                              type="number"
                              size="small"
                              placeholder="Weight"
                              value={variant.weight}
                              onChange={(e) => handleVariantChange(index, 'weight', e.target.value)}
                              inputProps={{ min: 0 }}
                              fullWidth
                              error={!!formErrors[`weight-${index}`]}
                            />
                            {formErrors[`weight-${index}`] && (
                              <FormHelperText error>{formErrors[`weight-${index}`]}</FormHelperText>
                            )}
                          </FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.height_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "height_unit", e)
                            }
                            inputValue={variant.height}
                            onChangeText={(e) =>
                              handleVariantChange(index, "height", e)
                            }
                            unitType={"distance"}
                            fieldName={"Height"}
                          ></InputWithUnit>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.width_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "width_unit", e)
                            }
                            inputValue={variant.width}
                            onChangeText={(e) =>
                              handleVariantChange(index, "width", e)
                            }
                            unitType={"distance"}
                            fieldName={"Width"}
                          ></InputWithUnit>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.length_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "length_unit", e)
                            }
                            inputValue={variant.length}
                            onChangeText={(e) =>
                              handleVariantChange(index, "length", e)
                            }
                            unitType={"distance"}
                            fieldName={"Length"}
                          ></InputWithUnit>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                          <Typography className="form-lable-style">Weight</Typography>
                          <FormControl fullWidth>
                            <TextField
                              style={{ backgroundColor: '#FFFFFF' }}
                              type="number"
                              size="small"
                              placeholder="Weight"
                              value={variant.weight}
                              onChange={(e) => handleVariantChange(index, 'weight', e.target.value)}
                              inputProps={{ min: 0 }}
                              fullWidth
                              error={!!formErrors[`weight-${index}`]}
                            />
                            {formErrors[`weight-${index}`] && (
                              <FormHelperText error>{formErrors[`weight-${index}`]}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Typography className="form-lable-style">Weight Unit</Typography>
                          <FormControl fullWidth>
                            <Select
                              sx={{
                                backgroundColor: '#FFFFFF'
                              }}
                              size="small"
                              value={variant.weight_unit}
                              onChange={(e) => handleVariantChange(index, 'weight_unit', e.target.value)}
                              fullWidth
                              error={!!formErrors[`weight_unit-${index}`]}
                            >
                              {weightUnitOptions.map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                  {unit}
                                </MenuItem>
                              ))}
                            </Select>
                            {formErrors[`weight_unit-${index}`] && (
                              <FormHelperText error>{formErrors[`weight_unit-${index}`]}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                          <Typography className="form-lable-style">
                            SKU
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              disabled={variant._id}
                              style={{ backgroundColor: "#FFFFFF" }}
                              type="text"
                              size="small"
                              variant="outlined"
                              placeholder="Enter SKU"
                              value={variant.sku}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "sku",
                                  e.target.value
                                )
                              }
                              fullWidth
                              error={!!formErrors[`sku-${index}`]}
                            />
                            {formErrors[`sku-${index}`] && (
                              <FormHelperText error>
                                {formErrors[`sku-${index}`]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography className="form-lable-style">
                            Inventory
                          </Typography>
                          <TextField
                            style={{ backgroundColor: "#FFFFFF" }}
                            type="number"
                            size="small"
                            variant="outlined"
                            placeholder=" Enter Inventory"
                            value={variant.inventory}
                            onChange={(e) =>
                              handleVariantChange(
                                index,
                                "inventory",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            display: "flex",
                          }}
                        >
                          {/* <div>
                            <Button
                              onClick={() => removeVariant(index)}
                              style={{ textTransform: "none" }}
                              variant="outlined"
                            >
                              Delete
                            </Button>
                          </div> */}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <></>
              )}
              <Button
                onClick={addVariant}
                style={{ textTransform: "none", color: "#0038FF" }}
              >
                <AddIcon fontSize="small" />
                <Typography style={{ marginLeft: 8 }}>
                  Add more Variants
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              {user?.role === "admin" ? (
                // <Autocomplete
                //   sx={{ marginBottom: 2 }}
                //   size="small"
                //   value={selectedClient}
                //   onChange={(event, newValue) => {
                //     setSelectedClient(newValue)
                //     onSelectClient && onSelectClient(newValue)
                //   }}
                //   options={clientData}
                //   getOptionLabel={(option) => option?.business_name || option?.name}
                //   renderOption={(props, option) => (
                //     <li {...props}>
                //       <Typography variant="body1">{option?.business_name || option?.name}</Typography>
                //     </li>
                //   )}
                //   renderInput={(params) => (
                //     <TextField
                //       {...params}
                //       label="Choose Client"
                //       margin="normal"
                //       error={formErrors?.selectedClient}
                //       helperText={formErrors?.selectedClient}
                //     />
                //   )}
                // />
                <ClientAutocomplete
                  selectedClient={selectedClient}
                  onClientChange={(client) => {
                    setSelectedClient(client);
                    onSelectClient && onSelectClient(client);
                  }}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => {
              setOpenModal(false);
              setFormErrors({});
            }}
            className="cancel-button-style"
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleEditProduct(e)}
            className="add-button-style"
            style={{ textTransform: "none" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedProduct?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          // if (client) {
          //   handleDeleteProduct && handleDeleteProduct(selectedProduct);
          //   setOpenConfirmation(false);
          // } else {
          confirmDelete(e);
          // }
        }}
      />
      <ProductDetailsModal
        product={selectedProduct}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
};

export default ProductList;
