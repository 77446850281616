import React, { useState, useEffect } from 'react'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box
} from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { emailTemplateService } from '../../utils/services/email-templateService'
import DashboardContainer from '../../components/dashboard-container'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CustomTypography from '../../globalStyles/fonts'
const EmailTemplateScreen = () => {
  const [templates, setTemplates] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [templateSubject, setTemplateSubject] = useState('')
  const [templateContent, setTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [total, setTotal] = useState(0)
  // Pagination states
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const handleOpenDeleteDialog = (template) => {
    setSelectedTemplate(template)
    setOpenDeleteDialog(true)
  }
  const fetchTemplates = () => {
    emailTemplateService
      .fetch(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        setTemplates(response.data)
        setTotal(response.total)
      })
      .catch((e) => {
        throw e
      })
  }
  useEffect(() => {
    // Fetch templates from the API
    fetchTemplates()
  }, [page, rowsPerPage])

  const handleSaveTemplate = () => {
    if (selectedTemplate) {
      // Edit existing template
      emailTemplateService
        .edit(selectedTemplate._id, {
          name: templateName,
          content: templateContent,
          subject: templateSubject
        })
        .then(() => {
          setTemplates((prev) =>
            prev.map((template) =>
              template.id === selectedTemplate._id
                ? { ...template, name: templateName, content: templateContent, subject: templateSubject }
                : template
            )
          )
        })
    } else {
      // Add new template
      emailTemplateService
        .create({ name: templateName, content: templateContent, subject: templateSubject })
        .then((response) => {
          setTemplates([...templates, response.data])
        })
    }
    fetchTemplates()
    setOpenDialog(false)
  }

  const handleEditTemplate = (template) => {
    setOpenDialog(true)
    setTemplateName(template.name)
    setTemplateContent(template.content)
    setTemplateSubject(template.subject)
    setSelectedTemplate(template)
  }

  const handleDeleteTemplate = () => {
    emailTemplateService.delete(selectedTemplate?._id).then(() => {
      setTemplates(templates.filter((template) => template._id !== selectedTemplate?._id))
    })
  }
  const handleViewTemplate = (template) => {
    setSelectedTemplate(template)
    setOpenViewDialog(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        console.log(x)
      }}
      onClickAddButton={() => {
        setOpenDialog(true)
        setTemplateName('')
        setTemplateContent('')
        setTemplateSubject('')
        setSelectedTemplate(null)
      }}
      hideUploadButton={true}
      header={'Template List'}
      title={'+ Add template'}
    >
      <TableContainer
        sx={{
          marginTop: 1,
          maxHeight: '70vh',
          overflowY: 'auto',
          paddingInline: 0.4
        }}
        component={Paper}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0 5px',
            paddingBottom: 7
          }}
        >
          <TableHead>
            <TableRow
              style={{
                marginBottom: 10,
                backgroundColor: '#fff',
                borderRadius: 8,
                boxShadow: '0px 0px 4px 0px #00000040'
              }}
            >
              <TableCell
                style={{
                  backgroundColor: '#EEEEEE',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: 8,
                  borderBottom: 'none',
                  textAlign: 'center',
                  padding: 10
                }}
              >
                <CustomTypography fontSize="14px" color="#000" fontWeight={600}>
                  Template Name
                </CustomTypography>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: '#EEEEEE',
                  borderBottom: 'none',
                  textAlign: 'center',
                  padding: 4,
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                  alignItems: 'center'
                }}
              >
                <CustomTypography fontSize="14px" color="#000" fontWeight={600}>
                  Actions
                </CustomTypography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template, index) => (
              <TableRow
                key={index}
                style={{
                  marginBottom: 10,
                  borderRadius: 8,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    borderBottom: 'none',
                    textAlign: 'center',
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    padding: 4,
                    backgroundColor: '#FFF'
                  }}
                >
                  <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                    {template.name}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 4,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: 'center',
                    backgroundColor: '#FFF'
                  }}
                >
                  <IconButton
                    style={{
                      color: '#007DFF',
                      padding: 0,
                      background: '#C3E1FF',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                    aria-label="view"
                    onClick={() => {
                      handleViewTemplate(template)
                    }}
                  >
                    <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png" />
                  </IconButton>

                  <IconButton
                    style={{
                      color: '#FEC50F',
                      padding: 0,
                      background: '#FEC50F33',
                      borderRadius: 5,
                      marginLeft: 10,
                      height: 30,
                      width: 30,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                    aria-label="edit"
                    onClick={() => handleEditTemplate(template)}
                  >
                    <BorderColorIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>
                  <IconButton
                    style={{
                      color: '#D13438',
                      padding: 0,
                      background: '#D1343833',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040',
                      marginTop: 5
                    }}
                    aria-label="delete"
                    onClick={() => handleOpenDeleteDialog(template)}
                  >
                    <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for adding/editing template */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{selectedTemplate ? 'Edit Template' : 'Add Template'}</DialogTitle>
        <DialogContent dividers>
          <TextField
            size="small"
            label="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            size="small"
            label="Template Subject"
            value={templateSubject}
            onChange={(e) => setTemplateSubject(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <ReactQuill
              style={{ height: 250 }}
              value={templateContent}
              onChange={setTemplateContent}
              theme="snow"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveTemplate} color="primary">
            {selectedTemplate ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <CustomTypography>Are you sure you want to delete this template?</CustomTypography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'red' }}
            onClick={handleDeleteTemplate}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openViewDialog} onClose={() => setOpenViewDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>View Template</DialogTitle>
        <DialogContent dividers>
          {selectedTemplate && (
            <>
              <CustomTypography fontSize="16px" fontWeight={500}>
                Template Name: {selectedTemplate.name}
              </CustomTypography>
              <CustomTypography style={{ marginTop: 2 }} fontSize="16px" fontWeight={500}>
                Subject: {selectedTemplate.subject}
              </CustomTypography>
              <Box mt={3}>
                <ReactQuill value={selectedTemplate.content} readOnly={true} theme="snow" />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenViewDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        component="div"
        count={templates.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </DashboardContainer>
  )
}

export default EmailTemplateScreen
