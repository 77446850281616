// config.js
const config = {
  // baseURL: 'http://192.168.31.3:3030/api'
  // baseURL: "http://10.0.40.229:3030/api",
  // baseURL: "http://216.48.191.27:3030/api",
  // baseURL: "http://10.0.41.23:3030/api",
  // baseURL: 'http://192.168.31.3:3030/api',
  // warehouseBaseURL: "http://localhost:3031/wms",
  baseURL: 'https://dropskip.app/api',
  warehouseBaseURL: 'https://dropskip.app/wms'
  // warehouseBaseURL: 'https://192.168.31.3:3031/wms'
  // baseURL: 'https://dropskip.app/api',
  // warehouseBaseURL: 'https://dropskip.app/wms'
}

export default config
