import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Typography from "@mui/material/Typography/Typography";
import { portTypes } from "../../utils/portTypes";
import { clientService } from "../../utils/services/clientService";
import { statusTypes } from "../../utils/services/statusTypes";
import AppContext from "../context/context";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { imageService } from "../../utils/services/imageService";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { currencyTypes } from "../../utils/services/currencyTypes";
import CustomTypography from "../../globalStyles/fonts";
import { countryService } from "../../utils/services/allCountry";
import ClientAutocomplete from "../clientAutocomplete";
import SupplierAutocomplete from "../supplierAutocomplete";
import { isMobile } from "../../globalStyles/mobile";
import InputWithUnit from "../Input-with-unit";

const AddModal = ({
  handleClose,
  open,
  onChangeName,
  value,
  nameValue,
  emailValue,
  addressValue1,
  addressValue2,
  mobileNoValue,
  countryCodeValue,
  onClickAdd,
  port,
  title,
  onChangeCountry,
  onChangeLocation,
  service,
  selectedCarrierService,
  setSelectedCarrierService,
  carrierList,
  zone,
  onChangeZoneNumber,
  handleChangeType,
  formErrors,
  onChangePostalCode,
  onChangeEmail,
  onChangeAddress1,
  onChangeMobileNo,
  onChangeCountryCode,
  client,
  zoneValue,
  selectedClientData,
  onSelectClient,
  product,
  onChangeDescription,
  onChangeType,
  handleChangeStatus,
  handleChangeCurrency,
  variants,
  setVariants,
  onImageUpload,
  description,
  setDescription,
  vendorValue,
  setVendorValue,
  onChangeVendor,
  carrier,
  modalHeader,
  nameHeader,
  onChangeState,
  onChangeCountryValue,
  state,
  country,
  zipCode,
  onChangeZipCode,
  onChangeAddress2,
  city,
  onChangeCity,
  businessName,
  onChangeBusiness,
  setCarrierSearchValue,
  fetchCarrierData,
  setPage,
  setCarrier,
  handleScroll,
  supplier,
  onChangeMinOrdQty,
  onChangeMaxOrdQty,
  companyUrl,
}) => {
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [selectedImages, setSelectedImages] = useState([]);
  const [coverImageIndex, setCoverImageIndex] = useState(-1);
  const [imageUrls, setImageUrls] = useState([]);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const [clientData, setClientData] = useState([]);
  const { user } = useContext(AppContext);
  const fileInputRef = useRef(null);
  const modalRef = useRef(null);

  const weightUnitOptions = ["mg", "g", "kg", "t", "mt", "gt", "lb", "oz"];
  const sizeOptions = ["Small", "Medium", "Large"];
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await countryService.fetchAll();
        setCountries(data);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    onChangeCountryValue && onChangeCountryValue(selectedCountry.name);
    onChangeCountryCode && onChangeCountryCode(selectedCountry.contactCode);
  };

  useEffect(() => {
    if (variants?.length === 0) {
      setVariants([
        {
          option: "",
          price: "",
          weight: "",
          weight_unit: "",
          height: "",
          height_unit: "",
          width: "",
          width_unit: "",
          length: "",
          length_unit: "",
          sku: "",
          inventory: "",
        },
      ]);
    }
  }, [open, setVariants]);

  const handleChooseImage = (index = null) => {
    setEditingImageIndex(index);
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const file = files[0];
      try {
        const imageData = await imageService.upload(file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImages((prevImages) => {
          const newImages = [...prevImages];
          if (editingImageIndex !== null) {
            newImages[editingImageIndex] = {
              image: imageData.key,
              feature_image: editingImageIndex === coverImageIndex,
            };
          } else {
            newImages.push({
              image: imageData.key,
              feature_image: false,
            });
          }
          onImageUpload && onImageUpload(newImages);
          return newImages;
        });
        setImageUrls((prevUrls) => {
          const newUrls = [...prevUrls];
          if (editingImageIndex !== null) {
            newUrls[editingImageIndex] = imageUrl;
          } else {
            newUrls.push(imageUrl);
          }
          return newUrls;
        });
        setEditingImageIndex(null);
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
  };

  const handleCheckCoverImage = (index) => {
    setCoverImageIndex(index === coverImageIndex ? -1 : index);
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.forEach((image, i) => {
        image.feature_image = i === index;
      });
      return updatedImages;
    });
  };

  const removeSelectedImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setImageUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );
  };

  const addVariant = () => {
    setVariants((prevState) => [
      ...prevState,
      {
        option: "",
        price: "",
        weight: "",
        weight_unit: "",
        sku: "",
        inventory: "",
      },
    ]);
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    if (updatedVariants[index]) {
      updatedVariants[index][field] = value;
      setVariants(updatedVariants);
    }
  };

  const resetSelectedImage = () => {
    setSelectedImages([]);
    setImageUrls([]);
    setEditingImageIndex();
  };

  const removeVariant = (indexToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleChangeDescription = (description) => {
    setDescription(description);
  };
  const fetchClientData = async (name = "") => {
    try {
      const response = await clientService.fetch(null, null, name);
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);

  const filterClients = (term) => {
    fetchClientData(term);
  };
  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [open]);

  const handleVendorChange = (e) => {
    // console.log(e,"directly");
    setVendorValue(e);
    onChangeVendor && onChangeVendor(e);
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose && handleClose();
        setSelectedClient(null);
        setSelectedImages([]);
        setImageUrls([]);
        setEditingImageIndex(null);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      ref={modalRef}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          <CustomTypography fontWeight={500} fontSize={20}>
            {modalHeader ?? `${nameValue ? "Edit" : "Create"} ${title}`}
          </CustomTypography>
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 20 }} />

      <DialogContent
        ref={modalRef}
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          scrollBehavior: "smooth",
        }}
        sx={{
          overflowY: "auto",
          "&::WebkitScrollbar": {
            width: "7px",
          },
          "&::WebkitScrollbar-track": {
            backgroundColor: "#D9D9D9",
            borderRadius: 14,
          },
          "&::WebkitScrollbar-thumb": {
            backgroundColor: "#273894",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={carrier ? 12 : 6}>
            <CustomTypography fontSize={15}>
              {nameHeader ?? `${title} Name`}
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
              size="small"
              placeholder={`Enter ${title} Name`}
              //label="Name"
              variant="outlined"
              value={nameValue}
              onChange={(e) => onChangeName && onChangeName(e.target.value)}
              fullWidth
              autoFocus
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          {supplier ? (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Select Client</CustomTypography>
                <ClientAutocomplete
                  selectedClient={selectedClientData}
                  onClientChange={(e) => {
                    onSelectClient && onSelectClient(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Min Order Quantity
                </CustomTypography>
                <TextField
                  sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
                  size="small"
                  placeholder={`Min order quantity`}
                  //label="Name"
                  variant="outlined"
                  value={nameValue}
                  onChange={(e) =>
                    onChangeMinOrdQty && onChangeMinOrdQty(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>
                  Max Order Quantity
                </CustomTypography>
                <TextField
                  sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
                  size="small"
                  placeholder={`Max order quantity`}
                  //label="Name"
                  variant="outlined"
                  value={nameValue}
                  onChange={(e) =>
                    onChangeMaxOrdQty && onChangeMaxOrdQty(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomTypography fontSize={15}>Email</CustomTypography>
                <TextField
                  disabled={selectedClientData?.id ? true : false}
                  sx={{ marginTop: 1 }}
                  type="email"
                  size="small"
                  placeholder="Enter Email"
                  variant="outlined"
                  value={emailValue}
                  onChange={(e) =>
                    onChangeEmail && onChangeEmail(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {product ? (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Category</CustomTypography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder=" Enter Type"
                  type="text"
                  fullWidth
                  onChange={(e) => onChangeType && onChangeType(e.target.value)}
                  error={formErrors?.product_type}
                  helperText={formErrors?.product_type}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomTypography fontSize={15}>Description</CustomTypography>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={handleChangeDescription}
                  placeholder="Enter product description"
                  style={{
                    height: isMobile() ? 70 : "100px",
                    marginBottom: "50px",
                    marginTop: 5,
                    borderRadius: 8,
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: isMobile() ? 5 : 0 }}>
                <CustomTypography fontSize={15}>Media</CustomTypography>
                <Box
                  sx={{
                    width: "100%",
                    border: 1,
                    borderColor: "#D1D1D1",
                    borderRadius: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 100,
                    mt: { xs: 2, sm: 1 },
                    p: 2,
                  }}
                >
                  <input
                    id="productImageInput"
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    multiple
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />

                  {selectedImages.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          gap: 2,
                          mt: 2,
                          maxHeight: 250,
                          overflowY: "auto",
                          border: "1px solid #000",
                          borderRadius: 1,
                          p: 2,
                        }}
                      >
                        {selectedImages.map((image, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              p: 1,
                              border: 1,
                              borderRadius: 1,
                              maxWidth: { xs: "100%", sm: "auto" },
                            }}
                          >
                            <img
                              src={imageUrls[index]}
                              alt={`Selected image ${index}`}
                              style={{
                                maxWidth: 100,
                                maxHeight: 100,
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => handleChooseImage(index)}
                              >
                                <EditIcon color="primary" />
                              </IconButton>
                              <input
                                type="checkbox"
                                checked={index === coverImageIndex}
                                onChange={() => handleCheckCoverImage(index)}
                              />
                              <IconButton
                                onClick={() => removeSelectedImage(index)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <IconButton onClick={() => handleChooseImage(null)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Button
                      sx={{
                        minWidth: { xs: 150, sm: 200 },
                        color: "#707070",
                        textTransform: "none",
                      }}
                      color="primary"
                      onClick={() => handleChooseImage(null)}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <IconButton aria-label="upload" sx={{ mb: 1, p: 0 }}>
                          <InsertPhotoIcon />
                        </IconButton>
                        <Typography variant="body2">Upload</Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomTypography fontSize={15}>Currency</CustomTypography>
                <Autocomplete
                  // sx={{ marginTop: 0.5 }}
                  onChange={(e, newValue) => handleChangeCurrency(newValue)}
                  size="small"
                  options={currencyTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="$ 00.00"
                      variant="outlined"
                      error={formErrors?.currency}
                      helperText={formErrors?.currency}
                      margin="dense"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTypography fontSize={15}>Status</CustomTypography>
                <Autocomplete
                  // sx={{ marginTop: 0.5 }}
                  onChange={(e, newValue) => handleChangeStatus(newValue)}
                  size="small"
                  options={statusTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Status"
                      variant="outlined"
                      error={formErrors?.status}
                      helperText={formErrors?.status}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTypography fontSize={15}>Vendor</CustomTypography>
                <SupplierAutocomplete
                  selectedSupplier={vendorValue}
                  onSupplierChange={handleVendorChange}
                  width="100%"
                  label="Select Vendor"
                />
                {formErrors.vendor && (
                  <FormHelperText error>{formErrors.vendor}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <CustomTypography fontWeight={500} fontSize={16}>
                  Variants
                </CustomTypography>
                {variants.map((variant, index) => (
                  <Card
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#EEEEEEEE",
                      marginTop: 10,
                    }}
                  >
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          style={{
                            color: "#D13438",
                            padding: 0,
                            background: "#D1343833",
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: "0px 0px 4px 0px #00000040",
                            marginTop: 5,
                          }}
                          aria-label="delete"
                          onClick={() => removeVariant(index)}
                        >
                          <img
                            style={{ height: 15, width: 15 }}
                            alt="delete"
                            src="/delete.png"
                          ></img>
                        </IconButton>
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <CustomTypography fontSize={14}>
                            Option
                          </CustomTypography>
                          <TextField
                            sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                            type="size"
                            size="small"
                            placeholder="Option"
                            variant="outlined"
                            value={variant.size}
                            onChange={(e) =>
                              handleVariantChange(
                                index,
                                "option",
                                e.target.value
                              )
                            }
                            fullWidth
                            // autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomTypography fontSize={14}>
                            {"Price (USD)"}
                          </CustomTypography>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                marginTop: 0.5,
                                backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              size="small"
                              placeholder="Enter Price"
                              value={variant.price}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              inputProps={{ min: 0 }}
                              fullWidth
                              error={!!formErrors[`price-${index}`]}
                            />
                            {formErrors[`price-${index}`] && (
                              <FormHelperText error>
                                {formErrors[`price-${index}`]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.weight_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "weight_unit", e)
                            }
                            inputValue={variant.weight}
                            onChangeText={(e) =>
                              handleVariantChange(index, "weight", e)
                            }
                            unitType={"weight"}
                            fieldName={"Weight"}
                          ></InputWithUnit>
                          {/* <CustomTypography fontSize={14}>Weight</CustomTypography>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                marginTop: 0.5,
                                backgroundColor: '#FFFFFF'
                              }}
                              type="number"
                              size="small"
                              placeholder="Weight"
                              value={variant.weight}
                              onChange={(e) => handleVariantChange(index, 'weight', e.target.value)}
                              inputProps={{ min: 0 }}
                              fullWidth
                              error={!!formErrors[`weight-${index}`]}
                            />
                            {formErrors[`weight-${index}`] && (
                              <FormHelperText error>{formErrors[`weight-${index}`]}</FormHelperText>
                            )}
                          </FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.height_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "height_unit", e)
                            }
                            inputValue={variant.height}
                            onChangeText={(e) =>
                              handleVariantChange(index, "height", e)
                            }
                            unitType={"distance"}
                            fieldName={"Height"}
                          ></InputWithUnit>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.width_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "width_unit", e)
                            }
                            inputValue={variant.width}
                            onChangeText={(e) =>
                              handleVariantChange(index, "width", e)
                            }
                            unitType={"distance"}
                            fieldName={"Width"}
                          ></InputWithUnit>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWithUnit
                            unitValue={variant.length_unit}
                            onChangeUnit={(e) =>
                              handleVariantChange(index, "length_unit", e)
                            }
                            inputValue={variant.length}
                            onChangeText={(e) =>
                              handleVariantChange(index, "length", e)
                            }
                            unitType={"distance"}
                            fieldName={"Length"}
                          ></InputWithUnit>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                          <CustomTypography fontSize={14}>Select Weight Unit</CustomTypography>
                          <FormControl fullWidth>
                            <Select
                              sx={{
                                marginTop: 0.5,
                                backgroundColor: '#FFFFFF'
                              }}
                              size="small"
                              value={variant.weight_unit}
                              onChange={(e) => handleVariantChange(index, 'weight_unit', e.target.value)}
                              label="Select Weight Unit"
                              fullWidth
                            >
                              {weightUnitOptions.map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                  {unit}
                                </MenuItem>
                              ))}
                            </Select>
                            {formErrors[`weight_unit-${index}`] && (
                              <FormHelperText error>{formErrors[`weight_unit-${index}`]}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid> */}

                        <Grid item xs={12} sm={6}>
                          <CustomTypography fontSize={14}>SKU</CustomTypography>
                          <TextField
                            sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Enter SKU"
                            value={variant.sku}
                            onChange={(e) =>
                              handleVariantChange(index, "sku", e.target.value)
                            }
                            fullWidth
                            error={!!formErrors[`sku-${index}`]}
                          />
                          {formErrors[`sku-${index}`] && (
                            <FormHelperText error>
                              {formErrors[`sku-${index}`]}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomTypography fontSize={14}>
                            Inventory
                          </CustomTypography>
                          <TextField
                            sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                            type="number"
                            size="small"
                            variant="outlined"
                            placeholder="Enter Inventory"
                            value={variant.inventory}
                            onChange={(e) =>
                              handleVariantChange(
                                index,
                                "inventory",
                                e.target.value
                              )
                            }
                            fullWidth
                            error={!!formErrors[`inventory-${index}`]}
                          />
                          {formErrors[`inventory-${index}`] && (
                            <FormHelperText error>
                              {formErrors[`inventory-${index}`]}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Button
                  onClick={addVariant}
                  style={{ textTransform: "none", color: "#0038FF" }}
                >
                  <AddIcon fontSize="small" />
                  <Typography style={{ marginLeft: 8 }}>
                    Add more Variants
                  </Typography>
                </Button>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {client ? (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Email</CustomTypography>
                <TextField
                  disabled={selectedClientData?.email ? true : false}
                  sx={{ marginTop: 1 }}
                  type="email"
                  size="small"
                  placeholder="Enter Email"
                  variant="outlined"
                  value={emailValue}
                  onChange={(e) =>
                    onChangeEmail && onChangeEmail(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Country</CustomTypography>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <TextField
                    disabled={country ? true : false}
                    select
                    sx={{ marginTop: 1 }}
                    size="small"
                    placeholder="Enter Country"
                    variant="outlined"
                    value={country}
                    onChange={handleCountryChange}
                    fullWidth
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.name} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomTypography fontSize={15}>Code</CustomTypography>
                <TextField
                  inputMode="numeric"
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  variant="outlined"
                  value={countryCodeValue}
                  onChange={(e) =>
                    onChangeCountryCode && onChangeCountryCode(e.target.value)
                  }
                  fullWidth
                  disabled={countryCodeValue ? true : false}
                  style={{ backgroundColor: "#DEDEDE" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTypography fontSize={15}>Mobile Number</CustomTypography>
                <TextField
                  inputMode="numeric"
                  sx={{ marginTop: 1 }}
                  type="mobileNumber"
                  size="small"
                  placeholder="Enter Mobile No."
                  variant="outlined"
                  value={mobileNoValue}
                  onChange={(e) =>
                    onChangeMobileNo && onChangeMobileNo(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.mobileNumber}
                  helperText={formErrors.mobileNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Business Name</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter Business Name"
                  variant="outlined"
                  value={businessName}
                  onChange={(e) =>
                    onChangeBusiness && onChangeBusiness(e.target.value)
                  }
                  fullWidth
                  autoFocus
                  error={!!formErrors.businessName}
                  helperText={formErrors.businessName}
                />
              </Grid>
              {companyUrl ? (
                <Grid item xs={12} sm={6}>
                  <CustomTypography fontSize={15}>
                    Company Website
                  </CustomTypography>
                  <TextField
                    disabled
                    sx={{ marginTop: 1 }}
                    type="text"
                    size="small"
                    placeholder="Enter Company Url"
                    variant="outlined"
                    value={companyUrl}
                    fullWidth
                    autoFocus
                    error={!!formErrors.companyUrl}
                    helperText={formErrors.companyUrl}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {/* <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Address 1</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter Address 1"
                  variant="outlined"
                  value={addressValue1}
                  onChange={(e) => onChangeAddress1 && onChangeAddress1(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.address}
                  helperText={formErrors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Address 2</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter Address 2"
                  variant="outlined"
                  value={addressValue2}
                  onChange={(e) => onChangeAddress2 && onChangeAddress2(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>Zip Code</CustomTypography>
                <TextField
                  inputMode="numeric"
                  sx={{ marginTop: 1 }}
                  type="number"
                  size="small"
                  placeholder="Enter ZipCode"
                  variant="outlined"
                  value={zipCode}
                  onChange={(e) => onChangeZipCode && onChangeZipCode(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.zipCode}
                  helperText={formErrors.zipCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>City</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter City"
                  variant="outlined"
                  value={city}
                  onChange={(e) => onChangeCity && onChangeCity(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.city}
                  helperText={formErrors.city}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTypography fontSize={15}>State</CustomTypography>
                <TextField
                  sx={{ marginTop: 1 }}
                  type="text"
                  size="small"
                  placeholder="Enter State"
                  variant="outlined"
                  value={state}
                  onChange={(e) => onChangeState && onChangeState(e.target.value)}
                  fullWidth
                  autoFocus
                  error={!!formErrors.state}
                  helperText={formErrors.state}
                />
              </Grid> */}
            </>
          ) : (
            <></>
          )}
          {zone ? (
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">Zone Number</Typography>
              <TextField
                sx={{ marginTop: 1 }}
                type="number"
                inputProps={{ min: 0 }}
                size="small"
                placeholder="Enter Zone Number"
                variant="outlined"
                value={zoneValue}
                onChange={(e) =>
                  onChangeZoneNumber && onChangeZoneNumber(e.target.value)
                }
                fullWidth
                autoFocus
                error={!!formErrors.zoneNumber}
                helperText={formErrors.zoneNumber}
              />
            </Grid>
          ) : (
            <></>
          )}
          {port ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Country</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Enter Country Name "
                  type="text"
                  fullWidth
                  onChange={(e) =>
                    onChangeCountry && onChangeCountry(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Location</Typography>
                <TextField
                  size="small"
                  placeholder="Enter Location"
                  margin="dense"
                  type="text"
                  fullWidth
                  onChange={(e) =>
                    onChangeLocation && onChangeLocation(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">
                  Postal Code
                </Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Enter Postal Code"
                  type="number"
                  fullWidth
                  onChange={(e) =>
                    onChangePostalCode && onChangePostalCode(e.target.value)
                  }
                  inputProps={{ min: 0 }}
                  error={!!formErrors.postal_code}
                  helperText={formErrors.postal_code}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Type</Typography>
                <Autocomplete
                  onChange={(e, newValue) => handleChangeType(newValue)}
                  sx={{ marginTop: 1 }}
                  size="small"
                  options={portTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Port type"
                      variant="outlined"
                      error={!!formErrors.type}
                      helperText={formErrors.type}
                    />
                  )}
                />
              </Grid>
            </>
          ) : service ? (
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                Choose Carrier
              </Typography>
              <Autocomplete
                size="small"
                value={selectedCarrierService?.name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedCarrierService(newValue);
                  } else {
                    setCarrierSearchValue("");
                    fetchCarrierData("", 0); // Fetch all carriers again if necessary
                  }
                }}
                ListboxProps={{
                  onScroll: handleScroll, // Attach scroll handler here
                }}
                options={carrierList}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option.name}</Typography>
                  </li>
                )}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "input") {
                    setCarrierSearchValue(newInputValue);
                    setPage(0); // Reset pagination on new search
                    setCarrier([]); // Clear previous carrier list
                  } else if (reason === "clear") {
                    setSelectedCarrierService(null);
                    setPage(0);
                    setCarrierSearchValue("");
                    fetchCarrierData("", 0); // Fetch all carriers again if necessary
                  }
                }}
                onOpen={() => {
                  setPage(0); // Reset the page to 0
                  fetchCarrierData("", 0); // Fetch all data when the dropdown is opened
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Carrier"
                    margin="dense"
                    error={!!formErrors.carrier}
                    helperText={formErrors.carrier}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}

          {!client && user?.role === "admin" && product ? (
            <Grid item xs={12} sm={product ? 12 : 6}>
              <Typography className="form-lable-style">
                Choose Client
              </Typography>
              {/* <Autocomplete
                size="small"
                value={selectedClient?._id}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue);
                  onSelectClient && onSelectClient(newValue);
                }}
                onInput={(e) => filterClients(e.target.value)}
                options={clientData}
                getOptionLabel={(option) =>
                  option?.business_name || option?.name
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">
                      {option?.business_name || option?.name}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Client"
                    margin="dense"
                    error={!!formErrors.selectedClient}
                    helperText={formErrors.selectedClient}
                  />
                )}
              /> */}
              <ClientAutocomplete
                selectedClient={selectedClientData}
                onClientChange={(e) => {
                  onSelectClient && onSelectClient(e);
                }}
              />
              {formErrors.vendor && (
                <FormHelperText error>{formErrors.client}</FormHelperText>
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: 15 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClose && handleClose();
            setSelectedClient({});
            resetSelectedImage();
          }}
          className="cancel-button-style"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            onClickAdd && onClickAdd(e);
            setSelectedClient(null);
            resetSelectedImage();
          }}
          className="add-button-style"
          style={{ textTransform: "none" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
