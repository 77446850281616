import React, { useState, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Icon, Tooltip } from '@mui/material' // Assuming MUI Icon is used
import AppContext from '../context/context'
import { Data, getIcon } from './sidebarData'

const SidebarLink = styled(Link)`
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 10px;

  &:hover {
    background: #0047ff;
    border-left: 4px solid #0047ff;
    cursor: pointer;
    border: 1px solid #abb7de;
    color: white;

    .MuiSvgIcon-root {
      color: white !important; /* Ensure specificity */
    }
  }
`

const DropdownLink = styled(Link)`
  background: #fff;
  height: 40px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  border-radius: 10px;

  &:hover {
    background: #83a0e8;
    cursor: pointer;
    color: white;
    border-radius: 10px;

    .MuiSvgIcon-root {
      color: white !important; /* Ensure specificity */
    }
  }
`
function getElementByPath(path) {
  // Helper function to recursively search subNav
  function searchSubNav(subNav) {
    for (const item of subNav) {
      if (item.path === path) {
        return item
      }
      if (item.subNav) {
        const found = searchSubNav(item.subNav)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  // Search the top-level elements
  for (const item of Data) {
    if (item.path === path) {
      return { parentNav: item }
    }
    if (item.subNav) {
      const found = searchSubNav(item.subNav)
      if (found) {
        return { subNav: found }
      }
    }
  }

  // If no match is found, return null
  return null
}
const SubMenu = ({ item, onClickSubMenu, onClickMenu, hideSideBar }) => {
  const { currentMenu, setCurrentMenu, currentSubMenu, setCurrentSubMenu, user, setUser } =
    useContext(AppContext)
  const location = useLocation()
  const [subnav, setSubnav] = useState(currentSubMenu.title ? true : false)
  const isSelected = (menuItem) => !item.subNav && currentMenu.title === menuItem.title
  // useEffect(() => {
  //   if (!user) {
  //     setUser(JSON.parse(localStorage.getItem('user')))
  //   }
  // }, [])
  useEffect(() => {
    if (localStorage.getItem('path')) {
      let currentNav = getElementByPath(localStorage.getItem('path'))
      if (currentNav?.subNav) {
        setCurrentSubMenu(getElementByPath(localStorage.getItem('path')).subNav)
        setCurrentMenu({ title: '' })
        setSubnav(true)
      } else if (currentNav?.parentNav) {
        setCurrentMenu(getElementByPath(localStorage.getItem('path')).parentNav)
        setCurrentSubMenu({ title: '' })
        setSubnav(true)
      } else {
        setCurrentMenu(Data[0])
        setSubnav(true)
      }
    }
  }, [])
  useEffect(() => {
    if (currentMenu.title === item.title) {
      setSubnav(true)
    }
  }, [currentMenu, item.title])
  return item?.roles?.includes(user?.role) ? (
    <div style={{ marginInline: 10, paddingBottom: 5 }}>
      <motion.div
        onClick={() => {
          localStorage.setItem('path', item?.path ? item.path : '/')
          if (item.subNav) {
            setSubnav(!subnav)
          } else {
            onClickMenu()
            setCurrentSubMenu('')
            setCurrentMenu(item)
            setSubnav(false)
          }
        }}
        whileHover={{ scale: 1.1 }}
      >
        <Tooltip title={hideSideBar ? item.title : ''}>
          <SidebarLink
            isSelected={isSelected(item)}
            subNav={item.subNav}
            style={{
              border: !item.subNav && currentMenu.title === item.title ? '1px solid #abb7de' : '',
              backgroundColor: !item.subNav && currentMenu.title === item.title ? '#3564dc' : ''
            }}
            to={item.path}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Icon
                style={{
                  marginBottom: 10,
                  height: 35,
                  width: 30,
                  color: isSelected(item) ? '#fff' : ''
                }}
              >
                {getIcon(item.icon, !item.subNav && currentMenu.title === item.title ? '#FFF' : '#000')}
              </Icon>

              <div>
                <p
                  style={{
                    marginLeft: 10,
                    fontFamily: 'Poppins',
                    fontSize: 14,
                    fontWeight: 500,
                    color: isSelected(item) ? '#fff' : ''
                  }}
                >
                  {hideSideBar ? '' : item.title}
                </p>
              </div>
            </div>
            <div>{item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}</div>
          </SidebarLink>
        </Tooltip>
      </motion.div>
      {subnav &&
        item.subNav?.map((subItem, index) => {
          const isSelectedSub = currentSubMenu.title === subItem.title
          return subItem.roles?.includes(user?.role) ? (
            <motion.div whileHover={{ scale: 1.1 }} key={index}>
              <Tooltip title={hideSideBar ? subItem.title : ''}>
                <DropdownLink
                  style={{
                    background: isSelectedSub && !currentMenu?.title ? '#3564dc' : '',
                    justifyContent: hideSideBar ? 'center' : 'start',
                    maxWidth: hideSideBar ? '50px' : '100%',
                    paddingLeft: hideSideBar ? 0 : '1rem'
                  }}
                  onClick={() => {
                    localStorage.setItem('path', subItem?.path ? subItem?.path : '/')
                    setCurrentSubMenu(subItem)
                    onClickSubMenu && onClickSubMenu()
                    setCurrentMenu('')
                  }}
                  to={subItem.path}
                >
                  <Icon
                    style={{
                      marginBottom: 10,
                      height: 35,
                      marginLeft: 5,
                      color: isSelectedSub ? '#fff' : ''
                    }}
                  >
                    {getIcon(subItem.icon, isSelectedSub && !currentMenu?.title ? '#FFF' : '#000')}
                  </Icon>

                  <div
                    style={{
                      color: isSelectedSub ? '#FFF' : ''
                    }}
                  >
                    <p
                      style={{
                        marginLeft: 10,
                        fontFamily: 'Poppins',
                        fontSize: 14,
                        fontWeight: 500,
                        textAlign: 'left'
                      }}
                    >
                      {hideSideBar ? '' : subItem.title}
                    </p>
                  </div>
                </DropdownLink>
              </Tooltip>
            </motion.div>
          ) : null
        })}
    </div>
  ) : (
    <></>
  )
}

export default SubMenu
