import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import AppContext from '../context/context'
import { loginService } from '../../utils/services/loginService.js'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { clientService } from '../../utils/services/clientService'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

// function Logo() {
//   return (
//     <img
//       src="/dropSkip.png"
//       alt="Company Logo"
//       className="self-center max-w-full aspect-[2.0] w-[137px]"
//     />
//   );
// }

function InputField({ icon, placeholder, onChange, type, value, endAdornment }) {
  return (
    <TextField
      size="small"
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      fullWidth
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        endAdornment: endAdornment && (
          <InputAdornment position="end">
            <IconButton>{endAdornment}</IconButton>
          </InputAdornment>
        ),
        sx: {
          color: 'white',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px transparent inset',
            caretColor: 'white'
          }
        }
      }}
      sx={{
        input: {
          color: 'white',
          backgroundColor: 'transparent'
        },
        '& .MuiOutlinedInput-root': {
          borderColor: 'white',
          border: '1px solid white',
          borderRadius: '4px',
          padding: '8px',
          '&:hover fieldset': {
            borderColor: 'white'
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white'
          }
        },
        '&::placeholder': {
          color: 'white'
        }
      }}
    />
  )
}

function LoginForm({ setIsAuthenticated }) {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const { user, setUser } = React.useContext(AppContext)
  // const fetchClientId = async (email) => {
  //   const response = await clientService.fetch(15, 0, '', email)
  //   localStorage.setItem('client_id', response?.data[0]?._id)
  // }

  const fetchClientByUser = async (user) => {
    console.log('user ===>', user)
    let clientUser = await clientService.fetchClientByUser(user._id)
    console.log(clientUser)
    localStorage.setItem('client_id', clientUser?.data[0]?.client)
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      if (!email || !password) {
        toast.error('Please enter email and password')
        return
      }
      const response = await loginService.login(email, password)
      setUser(response.user)
      if (response.accessToken) {
        setIsAuthenticated(true)
        localStorage.setItem('token', response.accessToken)
        localStorage.setItem('user', JSON.stringify(response.user))
        if (response.user?.role === 'client') {
          fetchClientByUser(response?.user)
          navigate('/')
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Login failed:', error)
      toast.error('Login failed. Please check your credentials.')
    }
  }

  return (
    <form className="flex relative flex-col max-w-full w-[300px] mt-5">
      <Box mb={3}>
        <Typography
          variant="body1"
          fontFamily="poppins"
          fontSize={14}
          fontWeight={600}
          color="white"
          textAlign={'left'}
          mb={1}
        >
          Email ID<span style={{ color: 'red' }}>*</span>
        </Typography>
        <InputField
          onChange={setEmail}
          type="text"
          icon={<PersonOutlineIcon style={{ color: 'white' }} />}
          placeholder="Enter Email ID"
          value={email}
          fullWidth
          style={{
            backgroundColor: '#fff',
            color: '#000',
            marginTop: 8,
            padding: '10px',
            borderRadius: '5px'
          }}
        />
      </Box>

      <Box mb={3}>
        <Typography
          variant="body1"
          fontFamily="poppins"
          fontSize={14}
          fontWeight={600}
          color="white"
          textAlign={'left'}
          mb={1}
        >
          Password<span style={{ color: 'red' }}>*</span>
        </Typography>
        <InputField
          onChange={setPassword}
          type={showPassword ? 'text' : 'password'}
          icon={<LockOutlinedIcon style={{ color: 'white' }} />}
          placeholder="Enter Password"
          value={password}
          fullWidth
          endAdornment={
            <IconButton
              style={{ color: 'white' }}
              onClick={() => setShowPassword(!showPassword)}
              aria-label="Toggle password visibility"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
          style={{
            backgroundColor: '#fff',
            color: '#000',
            marginTop: 8,
            padding: '10px',
            borderRadius: '5px'
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={4} fontFamily="poppins">
        {/* <FormControlLabel
          control={<Checkbox style={{ color: "#fff" }} />}
          label={
            <Typography color="white" fontFamily="poppins" fontSize={14}>
              Remember me
            </Typography>
          }
        /> */}
        <Link
          to="/forgot-password"
          style={{
            textDecoration: 'none',
            color: 'red',
            fontFamily: 'poppins',
            fontSize: 14
          }}
        >
          Forgot Password?
        </Link>
      </Box>

      <Button
        onClick={handleLogin}
        type="submit"
        className="justify-center items-center w-full py-3 text-lg font-semibold text-white uppercase whitespace-nowrap bg-[#FFC531] rounded-md shadow-sm"
        style={{
          backgroundColor: '#FEC50F',
          color: '#000',
          textTransform: 'none',
          fontFamily: 'poppins',
          fontSize: 16,
          fontWeight: 700
        }}
      >
        Login
      </Button>

      <Typography
        marginTop={3}
        fontSize={14}
        fontFamily="poppins"
        fontWeight={500}
        textAlign="center"
        color={'#fff'}
      >
        New to Dropskip?{' '}
        <a
          href="https://www.redefinedlogistics.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#fff' }}
        >
          Learn more.
        </a>
      </Typography>
    </form>
  )
}

const Login = () => {
  const { setIsAuthenticated, setUser } = React.useContext(AppContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  // const fetchClientId = async (email) => {
  //   const clientDetails = await clientService.fetch(15, 0, '', email)
  //   localStorage.setItem('client_id', clientDetails[0]?.id)
  // }
  const isMobile = useMediaQuery('(max-width:750px)')
  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuthenticated(true)
      setUser(JSON.parse(localStorage.getItem('user')))
      const user = JSON.parse(localStorage.getItem('user'))
      const path = localStorage.getItem('path')
      if (path && path !== 'undefined') {
        navigate(localStorage.getItem('path'))
      } else if (user?.role === 'client') {
        // fetchClientId(user.email)
        navigate('/')
      } else {
        navigate('/')
      }
    } else {
      console.log('***********')
    }
  }, [])

  React.useEffect(() => {
    if (state?._id) {
      toast.success('Account Created Successfully, Please Login', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored'
      })
    }
  }, [state?._id])

  return (
    <main
      style={{ backgroundColor: "#fff" }}
      className="flex flex-row justify-center items-center w-full min-h-screen"
    >
      {!isMobile ? (
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flex: 1,
            height: "100vh",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: 20,
              marginLeft: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent:"flex-start"
            }}
          >
            <img
              src="/DropSkipLogo.png"
              alt="Company Logo"
              className="max-w-full aspect-[2.0] w-[137px]"
              style={{ marginBottom: 10 ,marginLeft:20}} 
            />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              fontWeight={600}
              color={"black"}
              style={{ textAlign: "center" }} 
            >
              Powered by Redefined Logistics
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <img src="/mobile.png" alt="Company Logo" />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#151D48",
          height: "100vh",
          position: "relative",
        }}
      >
        {isMobile && (
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <img
              src="./DropSkipLogo.png"
              alt="Dropskip Logo"
              className="max-w-full aspect-[2.0] w-[100px]"
            />
            <Typography
              fontFamily={"poppins"}
              fontSize={"10px"}
              fontWeight={500}
              color={"#fff"}
              style={{ marginTop: 5, textAlign: "left" }}
            >
              Powered by Redefined Logistics
            </Typography>
          </div>
        )}

        <Button
          variant="outlined"
          style={{
            backgroundColor: "#FEC50F",
            color: "#000",
            textTransform: "none",
            position: "absolute",
            right: 20,
            top: 25,
            fontFamily: "poppins",
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={() =>
            window.open("https://www.redefinedlogistics.com/contact", "_blank")
          }
        >
          Contact Us
        </Button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <LoginForm setIsAuthenticated={setIsAuthenticated} />
        </div>
      </div>

      <ToastContainer />
    </main>
  );
}

export default Login
